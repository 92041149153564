import React from "react"
import { Helmet } from "react-helmet"
import Tabs from "../../../components/Tabs"
import TemplatePage from "../../../components/TemplatePage"
import ActeNormative from "../../../components/legislatie/ActeNormative"
import TabelRapoarte from "../../../components/legislatie/Rapoarte"
import {
  ActNormativ,
  getAllActeNormative,
} from "../../../utils/api/acteNormative"

export default function Legislatie({ location }) {
  const [tabValue, setTabValue] = React.useState(1)
  const [items, setItems] = React.useState<ActNormativ[]>([])

  React.useEffect(() => {
    const getActe = async () => {
      try {
        const apiResponse = await getAllActeNormative()
        if (apiResponse.type) {
          setItems(apiResponse.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getActe()
  }, [])

  return (
    <TemplatePage
      additionalClassName="templateFullContainerFaqScreen"
      location={location}
      title="Legislație"
      topPaneHeight={80 + 56}
      additionalTopPaneElement={
        <Tabs
          setTabValue={setTabValue}
          tabValue={tabValue}
          tabs={["Acte normative", "Rapoarte", "Proceduri"]}
        />
      }
    >
      <Helmet>
        <title>Rapoarte - Gazmir</title>
        <meta
          name="description"
          content="Vezi Rapoartele pentru activitatea de Furnizare, Distributie si Solutionare a plangerilor realizate de Gazmir."
        />
        <meta
          name="keywords"
          content="Raport gazmir,Gazmir raport,Raportare Gazmir,Raport furnizare gazmir,Raport distributie gazmir,Raport solutionare plangeri gazmir,Raport informare clienti gazmir,Raport informare consumatori gazmir,Rapoarte gazmir,Standard performanta furnizare gazmir,Standard performanta distributie gazmir,Gazmir standard performanta"
        />
      </Helmet>
      <div>
        {tabValue === 0 && (
          <ActeNormative items={items.filter(item => item.tip === 0)} />
        )}
        {tabValue === 1 && (
          <TabelRapoarte items={items.filter(item => item.tip === 1)} />
        )}
        {tabValue === 2 && (
          <ActeNormative items={items.filter(item => item.tip === 2)} />
        )}
      </div>
    </TemplatePage>
  )
}
